import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { graphql } from '../config'


const httpLink = createHttpLink({
  uri: graphql,
  credentials: "include"
})



const cache = new InMemoryCache();
const client = new ApolloClient({
  link: httpLink,
  cache: cache,
  resolvers: {}
  
})




export default client